<template>
  <div class="cart">
    <van-row>
      <van-col span="24">
        <span>购物车</span>
      </van-col>
    </van-row>
    <van-row style="margin-top: 20px;">
      <van-col span="12">
        <p class="so">配送</p>
      </van-col>
      <van-col span="12" style="position: relative;display: flex;align-items: center;">
        <p class="addtres">送至：成都青羊区文书院...<van-icon name="arrow" style="position: absolute;right: 0px;" /></p>
        <!-- <van-field v-model="value" is-link readonly placeholder="选择城市" @click="showPicker = true" />
                <van-popup v-model:show="showPicker" round position="bottom">
                    <van-picker :columns="columns" @cancel="showPicker = false" @confirm="onConfirm" />
                </van-popup> -->
      </van-col>
    </van-row>
    <van-row style="margin-top: 20px;">
      <van-col span="24">
        <div class="crads">
          <van-checkbox-group v-model="checked" ref="checkboxGroup" class="chebok">
            <van-checkbox name="a" checked-color="rgba(55, 159, 0, 1)" @click="checkAll">菜大全精品生鲜店铺</van-checkbox>
            <div style="margin-top: 20px;">
              <van-row>
                <van-col span="2" style="display: flex;align-items: center;">
                  <van-checkbox name="b" checked-color="rgba(55, 159, 0, 1)">
                  </van-checkbox>
                </van-col>
                <van-col span="22">
                  <van-row>
                    <van-col span="6">
                      <img src="../../assets/images/xg/detail.png" alt="" class="shop">
                    </van-col>
                    <van-col span="18">
                      <van-row>
                        <van-col span="24">
                          <p class="texttil"><span
                              style="background-color: rgba(209, 0, 0, 1);color: white;padding: 5px;border-radius: 10px;">年货节</span>【新鲜到货】智利进进口4...
                          </p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="24">
                          <p style="color: rgba(0, 0, 0, 0.5);font-size: 12px;">规格：1人份，酸奶...</p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="8">
                          <p style="color: red;" class="mony">￥{{ unitPprice }}</p>
                        </van-col>
                        <van-col span="16">
                          <div style="display: flex;justify-content: right;align-items: center;">
                            <!-- <van-button plain type="default" class="btns">-</van-button>
                                <span style="border: 1px gainsboro solid;font-size: 12px;padding: 2px;">x{{ nubers }}</span>
                                <van-button plain type="default" class="btns">+</van-button> -->
                            <van-field name="stepper">
                              <template #input>
                                <van-stepper v-model="values" @change="add" />
                              </template>
                            </van-field>
                          </div>
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>

            <div style="margin-top: 20px;">
              <van-row>
                <van-col span="2" style="display: flex;align-items: center;">
                  <van-checkbox name="c" checked-color="rgba(55, 159, 0, 1)">
                  </van-checkbox>
                </van-col>
                <van-col span="22">
                  <van-row>
                    <van-col span="6">
                      <img src="../../assets/images/xg/detail.png" alt="" class="shop">
                    </van-col>
                    <van-col span="18">
                      <van-row>
                        <van-col span="24">
                          <p class="texttil"><span
                              style="background-color: rgba(209, 0, 0, 1);color: white;padding: 5px;border-radius: 10px;">年货节</span>【新鲜到货】智利进进口4...
                          </p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="24">
                          <p style="color: rgba(0, 0, 0, 0.5);font-size: 12px;">规格：1人份，酸奶...</p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="8">
                          <p style="color: red;" class="mony">￥{{ unitPprice }}</p>
                        </van-col>
                        <van-col span="16">
                          <div style="display: flex;justify-content: right;align-items: center;">
                            <!-- <van-button plain type="default" class="btns">-</van-button>
                                <span style="border: 1px gainsboro solid;font-size: 12px;padding: 2px;">x{{ nubers }}</span>
                                <van-button plain type="default" class="btns">+</van-button> -->
                            <van-field name="stepper">
                              <template #input>
                                <van-stepper v-model="values" @change="add" />
                              </template>
                            </van-field>
                          </div>
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>

            </div>

          </van-checkbox-group>
        </div>
      </van-col>
    </van-row>

    <van-row style="margin-top: 20px;">
      <van-col span="24">
        <div class="crads">
          <van-checkbox-group v-model="checked" ref="checkboxGroup" class="chebok">
            <van-checkbox name="a" checked-color="rgba(55, 159, 0, 1)" @click="checkAll">菜大全精品生鲜店铺</van-checkbox>
            <div style="margin-top: 20px;">
              <van-row>
                <van-col span="2" style="display: flex;align-items: center;">
                  <van-checkbox name="b" checked-color="rgba(55, 159, 0, 1)">
                  </van-checkbox>
                </van-col>
                <van-col span="22">
                  <van-row>
                    <van-col span="6">
                      <img src="../../assets/images/xg/detail.png" alt="" class="shop">
                    </van-col>
                    <van-col span="18">
                      <van-row>
                        <van-col span="24">
                          <p class="texttil"><span
                              style="background-color: rgba(209, 0, 0, 1);color: white;padding: 5px;border-radius: 10px;">年货节</span>【新鲜到货】智利进进口4...
                          </p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="24">
                          <p style="color: rgba(0, 0, 0, 0.5);font-size: 12px;">规格：1人份，酸奶...</p>
                        </van-col>
                      </van-row>
                      <van-row style="margin-top: 10px;">
                        <van-col span="8">
                          <p style="color: red;" class="mony">￥{{ unitPprice }}</p>
                        </van-col>
                        <van-col span="16">
                          <div style="display: flex;justify-content: right;align-items: center;">
                            <!-- <van-button plain type="default" class="btns">-</van-button>
                                <span style="border: 1px gainsboro solid;font-size: 12px;padding: 2px;">x{{ nubers }}</span>
                                <van-button plain type="default" class="btns">+</van-button> -->
                            <van-field name="stepper">
                              <template #input>
                                <van-stepper v-model="values" @change="add" />
                              </template>
                            </van-field>
                          </div>
                        </van-col>
                      </van-row>
                    </van-col>
                  </van-row>
                </van-col>
              </van-row>
            </div>

          </van-checkbox-group>
        </div>
      </van-col>
    </van-row>
    <van-submit-bar :price="3050" button-text="去付款" @submit="onSubmit" button-color="rgba(55, 159, 0, 1)">
      <van-checkbox v-model="checkeds" checked-color="rgba(55, 159, 0, 1)">全选</van-checkbox>
      <!-- <template #tip>
        你的收货地址不支持配送, <span @click="onClickLink">修改地址</span>
      </template> -->
    </van-submit-bar>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { Toast } from "vant";
import { shoppingCartList } from "@/utils/api.js";
// const columns = ['杭州', '宁波', '温州', '绍兴', '湖州', '嘉兴', '金华'];
// const result = ref('');
// const showPicker = ref(false);

// const onConfirm = (value) => {
//     result.value = value;
//     showPicker.value = false;
// }
const checked = ref([]);
const checkeds = ref([]);
const checkboxGroup = ref(null);
const checkAll = () => {
  if (checkboxGroup.value == null) {
    checkboxGroup.value.toggleAll(true);
  } else {
    checkboxGroup.value.toggleAll();
  }

}
const values = ref(1);
const unitPprice = ref('50.88')
const unit = ref('50.88')
const add = () => {
  console.log(111);
  unitPprice.value = unit.value * values.value
}
const onSubmit = () => Toast('去付款');
const onClickLink = () => Toast('修改地址');

//获取购物车列表
const page =ref(1)
const page_size = ref(5)
getcart(page.value)
function getcart(pagenub) {
  shoppingCartList({ page: pagenub, page_size: page_size.value }).then(async (res) => {
        if ((await res.code) === 200) {
          console.log(res,111);
            // const shopLists = res.data.list;
            // totalElements.value = res.data.count;
            // hotShop.value = res.data.list.filter((v) => v.is_hot == 1);
            // recommendShop.value = res.data.list.filter((v) => v.is_hot == 2);
            // shopList.value.push(...shopLists)
            // console.log(shopList.value);
        }
    });
  }
</script>

<style lang="scss" scoped>
.cart {
  padding: 20px;

  .so {
    font-size: 36px;
  }

  .addtres {
    font-size: 24px;
    // position: absolute;
    // right: 0px;
    // bottom: 0px;
  }

  .crads {
    width: 100%;
    background: white;
    height: auto;
    border-radius: 10px;

    // padding: 20px;
    .chebok {
      padding: 20px;
    }

    .shop {
      width: 130px;
      height: 130px;
    }

    .texttil {
      font-size: 26px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }

    .mony {
      font-size: 36px;
    }

    .btns {
      padding: 10px;
    }
  }
}
</style>
<style lang="scss">
.van-submit-bar {
  bottom: 110px !important;
}

.van-checkbox__label {
  line-height: normal !important;
}

.van-submit-bar__bar {
  height: 150px !important;
}

.van-button--normal {
  padding: 0 !important;
}

.van-checkbox__label {
  font-size: 32px !important;
}

.van-checkbox__icon {
  height: auto !important;
  line-height: normal !important;

  .van-icon {
    line-height: normal !important;
    width: 100% !important;
    height: auto !important;
  }
}

.van-submit-bar__button {
  width: 200px !important;
  height: 100px !important;
}

.van-field__control--custom {
  justify-content: right !important;
  align-items: center !important;
}
.van-stepper__minus {
  width: 40px;
  height: 40px;
}
.van-stepper__plus{
  width: 40px;
  height: 40px;
}
.van-stepper__input{
  width: 40px;
  height: 40px;
}
</style>